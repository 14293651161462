import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class WebsiteBlogService {


    paginate(slug, filter, index = null) {
        let url = API_URL + 'user/website/' + slug + '/blog';
        let categoryset = false;
        if (index != null)
            url = url + '?blog=' + index
        if (filter != null && filter != undefined) {
            if (filter.category_id != null && filter.category_id != undefined) {
                url = url + '?category_id=' + filter.category_id
                categoryset = true
            }
            if (filter.status != null && filter.status != undefined) {
                if (categoryset)
                    url = url + '&status=' + filter.status
                else
                    url = url + '?status=' + filter.status
            }
        }
        return apiService.get(url);
    }

    create(slug, data) {
        let url = API_URL + 'user/website/' + slug + '/blog'
        return apiService.post(url, data);
    }

    update(slug, id, data) {
        let url = API_URL + 'user/website/' + slug + '/blog/' + id+'/update'
        return apiService.post(url, data);
    }

    delete(slug, id) {
        let url = API_URL + 'user/website/' + slug + '/blog/' + id
        return apiService.delete(url);
    }
    show(slug, id) {
        let url = API_URL + 'user/website/' + slug + '/blog/' + id
        return apiService.get(url);
    }

    recoverBlog(slug, id){
        let url = API_URL + 'user/website/' + slug + '/blog/' + id+'/recover'
        return apiService.get(url);
    }


}
